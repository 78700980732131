import type { AccountOrder } from 'account/types/order'
import type { Order, OrderParams } from '@/types/order'
import type ResultResponse from '@/types/result-response'
import type { CreatorRecord } from '@/types/creator'
import type { AttributeItem } from '@/types/variant'

export default () => {
  const completedStatuses = ['Approved', 'Rejected']
  const getAttributeValue = (attributes: AttributeItem[], name: string) =>
    attributes.find((attribute: AttributeItem) => attribute.name === name)
      ?.value

  const useAccountOrder = (order: Order): AccountOrder => {
    const { $price, $__ } = useNuxtApp()
    const { customer } = useCustomerStore()
    const lineItem = order.lineItems[0]
    const creatorRecord = getAttributeValue(
      lineItem.variant.attributes,
      'creator',
    ) as CreatorRecord
    const creatorId = (
      getAttributeValue(lineItem.variant.attributes, 'owner') as
        | {
            id: string
            typeId: string
          }
        | undefined
    )?.id
    const isCreator = customer?.id === creatorId
    const author = creatorRecord.obj
    const counterparty = isCreator
      ? order.shippingAddress?.additionalAddressInfo || ''
      : $__(author.masterData.current.name)
    const avatar = useProfilePicture(
      isCreator ? order.customerId : creatorId,
    ).value
    const dueDateISO8601 = order?.custom?.fields?.due_date
    const dueDate = dueDateISO8601
      ? new Date(dueDateISO8601).toLocaleDateString()
      : ''
    const rating =
      order.custom.fields?.[isCreator ? 'buyer_rating' : 'creator_rating']
    const tags = order.custom.fields?.customer_tags
    const productSlug = lineItem.productSlug
      ? `/collab/${$__(lineItem.productSlug)}`
      : ''

    const status = order?.state?.obj?.key || 'NewOrder'

    const isDelayed =
      dueDateISO8601 && !completedStatuses.includes(status)
        ? new Date(dueDateISO8601) < new Date()
        : false

    return {
      author,
      counterparty,
      avatar,
      dueDate,
      id: order.id,
      url: productSlug,
      isDelayed,
      rating,
      tags,
      status,
      title: $__(lineItem.name),
      total: $price(lineItem.totalPrice),
    }
  }

  const fetchOrders = async (options?: OrderParams) => {
    const url = options?.asCreator ? 'me/commissions' : 'me/orders'

    try {
      const params: {
        expand: string[]
        offset: number
        limit: number
        where?: string
        sort?: string
      } = {
        expand: ['lineItems[*].variant.attributes[*].value', 'state'],
        offset: 0,
        limit: 500,
        sort: options?.sort,
      }

      const route = useRoute()
      const tagsArray = (route.query.tags as string | undefined)?.split(',')
      if (options?.withDefinedTags) {
        params.where = `custom(fields(customer_tags is defined))`
      } else if (tagsArray?.length) {
        const decodedTagsArray = tagsArray.map((tag) => decodeURIComponent(tag))
        const formattedTagsString = decodedTagsArray
          .map((tag) => `"${tag}"`)
          .join(', ')
        params.where = `custom(fields(customer_tags contains any (${formattedTagsString})))`
      }

      if (options?.isActive) {
        if (options?.asCreator) {
          params.where = `state(id not in (:rejected, :approved)) and customerId="${options.buyerId}"`
        } else {
          params.where = `state(id not in (:rejected, :approved)) and lineItems(variant(attributes(name="creator" and value(id="${options.profileId}"))))`
        }
      }

      const { data, error } = await useApi<ResultResponse<Order>>(url, {
        params,
      })

      if (error.value && !options?.chatPage) {
        throw new Error('No orders data found.')
      }

      return data.value?.results
    } catch (error) {
      throw createError({
        statusCode: 404,
        statusMessage: (error as Error)?.message,
        fatal: true,
      })
    }
  }

  return {
    completedStatuses,
    fetchOrders,
    useAccountOrder,
  }
}
